import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useCurrentSchedule } from 'store/planning';

import { Form } from './styles';

const Step1 = ({ setStep }) => {
  const schedule = useCurrentSchedule();
  const onNext = useCallback(() => setStep(2), [setStep]);

  return <Form data={schedule} onNext={onNext} />;
};

Step1.propTypes = { setStep: PropTypes.func.isRequired };

export default Step1;
