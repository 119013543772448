import React, { useCallback, useMemo } from 'react';
import { paths } from 'ramda';
import PropTypes from 'prop-types';

import { EMAIL, SUB_SENDER, PAYLOAD, MESSAGE, META, CREATED, SEVERITY, PAGE_URL } from 'store/bugReports';

import Panel from './Panel';
import { useBugReportChecker } from './hooks';
import { Container, PageUrl, Link, Text } from './styles';

const BugReport = ({ history, match }) => {
  const { id } = match.params;
  const redirectBack = useCallback(() => history.push('/bugreports'), [history]);
  const report = useBugReportChecker(id, redirectBack);
  const [email, user = {}, kind, date, url, message] = useMemo(
    () => paths([[EMAIL], [SUB_SENDER], [PAYLOAD, SEVERITY], [META, CREATED], [PAGE_URL], [MESSAGE]], report),
    [report]
  );

  return (
    <Container>
      <Panel redirectBack={redirectBack} user={user} email={email} kind={kind} date={date} />
      {url && (
        <PageUrl>
          Zul. besuchte Seite:{' '}
          <Link href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </Link>
        </PageUrl>
      )}
      <Text>{message}</Text>
    </Container>
  );
};

BugReport.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default BugReport;
