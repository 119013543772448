import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Wrapper, Text } from './styles';

const Section = ({ title, current, amount, withCounter, className }) => (
  <Container className={className}>
    <Title>{title}</Title>
    {withCounter && (
      <Wrapper>
        <Text>Schritt</Text>
        <Text>{`${current}/${amount}`}</Text>
      </Wrapper>
    )}
  </Container>
);

Section.defaultProps = {
  current: 0,
  amount: 0,
  withCounter: false,
  className: undefined,
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  current: PropTypes.number,
  amount: PropTypes.number,
  withCounter: PropTypes.bool,
  className: PropTypes.string,
};

export default Section;
