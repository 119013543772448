import moment from 'moment';
import { pipe, propOr, map, applySpec, prop, props, filter, join, slice, path, pathOr, when, not, cond, test, T } from 'ramda';

import { ID, STATUS, SUB_SENDER, EMAIL, MESSAGE, PAYLOAD, SEVERITY, META, CREATED } from 'store/bugReports';
import { LAST_NAME, FIRST_NAME } from 'store/session';

export const tableHead = [
  { id: `${SUB_SENDER}.${LAST_NAME}`, content: 'Von', width: '30%', withoutSort: true },
  { id: MESSAGE, content: 'Text', width: '40%', withoutSort: true },
  { id: `${META}.${CREATED}`, content: 'Datum', width: '15%' },
  { id: `${PAYLOAD}.${SEVERITY}`, content: 'Level', width: '15%', withoutSort: true },
];

export const handleFields = map(
  applySpec({
    id: prop(ID),
    status: prop(STATUS),
    sender: ($) =>
      pipe(
        propOr({}, SUB_SENDER),
        props([LAST_NAME, FIRST_NAME]),
        filter(Boolean),
        join(', '),
        when(not, () => propOr('–', EMAIL, $))
      )($),
    message: pipe(propOr('–', MESSAGE), slice(0, 70)),
    date: pipe(path([META, CREATED]), (date) => date && moment(date).format('DD.MM.YYYY')),
    severity: pathOr('INFO', [PAYLOAD, SEVERITY]),
    color: pipe(
      pathOr('info', [PAYLOAD, SEVERITY]),
      cond([
        [test(/info/i), () => '#009838'],
        [test(/error/i), () => '#f00'],
        [T, () => ''],
      ])
    ),
  })
);
