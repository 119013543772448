import React, { useMemo, useCallback, useEffect } from 'react';
import { equals, applySpec, path, inc } from 'ramda';

import { useOrganizationActions, useAlerts, ID, FROM, TO, KIND, SPECIALTY } from 'store/organizations';
import { useAsyncState } from 'utils/useAsyncState';
import QuestionContainer from 'components/QuestionContainer';
import PaginationDropDown from 'components/PaginationDropDown';
import Button from 'components/Button';
import Message from 'components/Message';
import Radios from 'components/RadiosNew';

import { RADIOS, PARAMS, getName } from './utils';
import { Wrapper, Label } from './styles';

const Duplicates = () => {
  const [kind, setKind] = useAsyncState(RADIOS[0].value);
  const [values, setValues] = useAsyncState(() => ({ [FROM]: null, [TO]: null }));
  const [renderKey, setRenderKey] = useAsyncState(0);
  const valueFrom = values[FROM];
  const valueTo = values[TO];
  const isValid = useMemo(() => valueFrom && valueTo && !equals(valueFrom, valueTo), [valueFrom, valueTo]);
  const { mergeOrganizations, fetchCollectOrganizations } = useOrganizationActions();
  const { action, loading, success, error, resetAlerts } = useAlerts(mergeOrganizations);
  const updateKind = useCallback(
    (value) => {
      setKind(value);
      resetAlerts();
      setValues({ [FROM]: null, [TO]: null });
      setRenderKey(inc);
    },
    [resetAlerts, setKind, setRenderKey, setValues]
  );
  const getOrganizations = useCallback(
    (args) => {
      const [k, s] = kind.split('&');

      return fetchCollectOrganizations({ ...args, ...PARAMS, [KIND]: Number(k), ...(Boolean(s) && { [SPECIALTY]: s }) });
    },
    [fetchCollectOrganizations, kind]
  );

  const handleChangeFrom = useCallback(
    (data) => {
      resetAlerts();

      setValues(($) => ({ ...$, [FROM]: data }));
    },
    [setValues, resetAlerts]
  );
  const handleChangeTo = useCallback(
    (data) => {
      resetAlerts();

      setValues(($) => ({ ...$, [TO]: data }));
    },
    [setValues, resetAlerts]
  );

  const filterFromItems = useCallback(
    (items) => (valueTo?.[ID] ? items.filter(({ [ID]: id }) => id !== valueTo[ID]) : items),
    [valueTo]
  );

  const filterToItems = useCallback(
    (items) => (valueFrom?.[ID] ? items.filter(({ [ID]: id }) => id !== valueFrom[ID]) : items),
    [valueFrom]
  );

  const handleSubmit = useCallback(() => {
    action(
      applySpec({
        [FROM]: path([FROM, ID]),
        [TO]: path([TO, ID]),
      })(values)
    );
  }, [action, values]);

  const disabled = Boolean(loading || success);

  useEffect(() => {
    if (!success) return () => null;

    const timer = setTimeout(() => {
      resetAlerts();
      setValues({ [FROM]: null, [TO]: null });
      setRenderKey(inc);
    }, 3000);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [resetAlerts, setRenderKey, setValues, success]);

  return (
    <QuestionContainer label="Partner zusammenlegen">
      <Radios value={kind} label="Partner" items={RADIOS} setValue={updateKind} disabled={disabled} />
      <Wrapper>
        <Label>Von Partner</Label>
        <PaginationDropDown
          key={renderKey}
          idKey={ID}
          nameKey={getName}
          action={getOrganizations}
          placeholder="Suchen"
          value={valueFrom}
          onChange={handleChangeFrom}
          applyFilter={filterFromItems}
          disabled={disabled}
          onlyOne
        />
      </Wrapper>
      <Wrapper>
        <Label>nach Partner</Label>
        <PaginationDropDown
          key={renderKey}
          idKey={ID}
          nameKey={getName}
          action={getOrganizations}
          placeholder="Suchen"
          value={valueTo}
          onChange={handleChangeTo}
          applyFilter={filterToItems}
          disabled={disabled}
          onlyOne
        />
      </Wrapper>
      <Button type="button" onClick={handleSubmit} disabled={disabled || !isValid} color="success">
        Daten zusammenlegen
      </Button>
      {error && <Message type="error">{error}</Message>}
      {success && <Message type="success">{success}</Message>}
    </QuestionContainer>
  );
};

export default Duplicates;
