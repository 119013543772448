import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: var(--border-radius8);
  background: none;
  font-weight: ${({ bold }) => (bold ? 'var(--font-weight600)' : 'var(--font-weight400)')};
  &:hover {
    background-color: var(--color-greyF8);
  }
`;

export const Item = styled.div`
  width: ${({ width }) => `calc(${width} - 10px)`};
  font-size: var(--font-size20);
  color: ${({ $color }) => $color || 'var(--color-grey6A)'};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
`;

export const Status = styled.p`
  width: ${({ width }) => `calc(${width} - 10px)`};
  opacity: 0.88;
  font-size: var(--font-size10);
  font-weight: var(--font-weight600);
  line-height: 1.2;
  letter-spacing: 0.51px;
  text-align: left;
  color: ${({ $color }) => $color || 'var(--color-grey6A)'};
`;
