import { takeEvery, takeLatest, call, put, debounce, throttle, select, takeLeading } from 'redux-saga/effects';
import { prop, cond, pick, equals, path, keys, isEmpty, omit } from 'ramda';
import { string, number } from 'yup';
import { isURL, isEmail } from 'validator';

import { getUserProp } from 'store/session/selectors';
import { withAlert } from 'store/alerts';
import api from 'api';

import {
  KIND,
  NAME,
  ORGANIZATION_KINDS,
  ORGANIZATION_STATUS,
  CONTACTS,
  ZIP_CODE,
  CANTON,
  ORG_URL,
  EMAIL,
  SPECIALTY,
  CITY,
  ADDRESS,
} from './consts';
import {
  updateNurseHomes,
  updateNurseHome,
  updateOpticians,
  updateOptician,
  updateClinics,
  updateClinic,
  deleteNurseHome,
  deleteOptician,
  deleteClinic,
  updatePagination,
  updateCurrentOrganization,
} from './actions';
import {
  CREATE_ORGANIZATION,
  FETCH_ORGANIZATIONS,
  FETCH_INIT_COLLECT_ORGANIZATIONS,
  FETCH_COLLECT_ORGANIZATIONS,
  FETCH_ORGANIZATION,
  FETCH_COLLECT_ORGANIZATION,
  UPDATE_ORGANIZATION,
  FETCH_CURRENT_ORGANIZATION,
  REMOVE_ORGANIZATION,
  MERGE_ORGANIZATIONS,
} from './types';
import { ID, COUNT, HAS_MORE, FIELDS, CURSOR, LIMIT, SORT_BY, SORT_DIR, ASC, SUB_ORGANIZATION, STATUS } from '.';

const updateActions = {
  [ORGANIZATION_KINDS.NURSING_HOME]: updateNurseHome,
  [ORGANIZATION_KINDS.OPTICIAN]: updateOptician,
  [ORGANIZATION_KINDS.CLINIC]: updateClinic,
};

const deleteActions = {
  [ORGANIZATION_KINDS.NURSING_HOME]: deleteNurseHome,
  [ORGANIZATION_KINDS.OPTICIAN]: deleteOptician,
  [ORGANIZATION_KINDS.CLINIC]: deleteClinic,
};

function* createOrganization({ payload }) {
  const data = yield call(api.postOrganization, payload);
  const action = prop(payload[KIND], updateActions);

  yield put(action(data));

  return { success: prop(ID, data) };
}

function* fetchOrganizations({ payload }) {
  const data = yield call(api.getOrganizations, payload);

  const action = cond([
    [equals(ORGANIZATION_KINDS.NURSING_HOME), (kind, list) => updateNurseHomes(list)],
    [equals(ORGANIZATION_KINDS.OPTICIAN), (kind, list) => updateOpticians(list)],
    [equals(ORGANIZATION_KINDS.CLINIC), (kind, list) => updateClinics(list)],
  ])(payload[KIND], data.data);

  if (action) {
    yield put(action);
    yield put(updatePagination({ kind: payload[KIND], value: pick([COUNT, HAS_MORE], data) }));
  }
}

const collectQuery = {
  [FIELDS]: [NAME],
  [CURSOR]: 0,
  [LIMIT]: 10,
  [SORT_BY]: NAME,
  [SORT_DIR]: ASC,
  [STATUS]: path([0, 'id'], ORGANIZATION_STATUS),
};
function* fetchCollectOrganizations({ payload }) {
  return { success: yield call(api.getOrganizations, { ...collectQuery, ...payload }) };
}

function* fetchOrganization({ payload }) {
  const data = yield call(api.getOrganization, payload);
  const action = prop(data[KIND], updateActions);

  if (action) yield put(action(data));

  return { success: data };
}

function* fetchCollectOrganization({ payload }) {
  return {
    success: yield call(api.getOrganization, payload, { [FIELDS]: [NAME, SPECIALTY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS] }),
  };
}

const validateOrganization = (data = {}) => {
  const contactKeys = keys(data[CONTACTS] || {});
  const isValidZipCode =
    !data[ZIP_CODE] || (string().min(4).isValidSync(data[ZIP_CODE]) && number().positive('').isValidSync(data[ZIP_CODE]));
  const isValidCanton = !data[CANTON] || (string().length(2).isValidSync(data[CANTON]) && !number().isValidSync(data[CANTON]));
  const isValidUrl = !data[ORG_URL] || isURL(data[ORG_URL].trim());

  const contactErrors = contactKeys.reduce((acc, key) => {
    const contact = data[CONTACTS][key];
    const isValidEmail = !contact[EMAIL] || isEmail(contact[EMAIL].trim());

    if (!isValidEmail) {
      acc[key] = { [EMAIL]: 'E-Mail-Adresse ungültig' };
    }

    return acc;
  }, {});

  const errors = {
    ...(!isValidZipCode && { [ZIP_CODE]: 'Die Postleitzahl ist ungültig und kann nicht gespeichert werden.' }),
    ...(!isValidCanton && {
      [CANTON]:
        'Der Kanton ist ungültig und kann nicht gespeichert werden. Bitte geben Sie die Abkürzung mit zwei Buchstaben ein (z.B. ZH).',
    }),
    ...(!isValidUrl && {
      [ORG_URL]:
        'Die URL ist ungültig und kann nicht gespeichert werden. Gültige Beispiele: augenmobil.ch, www.augenmobil.ch oder https://augenmobil.ch.',
    }),
    ...(!isEmpty(contactErrors) && { [CONTACTS]: contactErrors }),
  };

  return {
    errors,
    data: {
      ...omit(keys(errors), data),
      ...(data[CONTACTS]
        ? {
            [CONTACTS]: contactKeys.reduce((acc, key) => {
              acc[key] = contactErrors[key] ? omit(keys(contactErrors[key]), data[CONTACTS][key]) : data[CONTACTS][key];

              return acc;
            }, {}),
          }
        : {}),
    },
  };
};

function* updateOrganization({ payload }) {
  const { errors, data } = validateOrganization(payload);
  const response = yield call(api.patchOrganization, data);
  const action = prop(response[KIND], updateActions);

  yield put(action(response));

  return { success: true, errors };
}

function* fetchCurrentOrganization() {
  const orgId = yield select(getUserProp(SUB_ORGANIZATION));
  if (orgId) {
    const data = yield call(api.getOrganization, orgId);

    yield put(updateCurrentOrganization(data));
  }
}

function* removeOrganization({ payload }) {
  const data = yield call(api.deleteOrganization, payload);

  const action = prop(data[KIND], deleteActions);

  if (action) {
    yield put(action(data));
  }
}

function* mergeOrganizations({ payload }) {
  return yield call(api.mergeOrganizations, payload);
}

export default function* watchOrganizations() {
  yield takeEvery(CREATE_ORGANIZATION, withAlert(createOrganization));
  yield throttle(500, FETCH_ORGANIZATIONS, withAlert(fetchOrganizations));
  yield takeEvery(FETCH_INIT_COLLECT_ORGANIZATIONS, withAlert(fetchCollectOrganizations));
  yield throttle(500, FETCH_COLLECT_ORGANIZATIONS, withAlert(fetchCollectOrganizations));
  yield debounce(500, UPDATE_ORGANIZATION, withAlert(updateOrganization));
  yield takeEvery(FETCH_ORGANIZATION, withAlert(fetchOrganization));
  yield takeLatest(FETCH_CURRENT_ORGANIZATION, withAlert(fetchCurrentOrganization));
  yield takeLeading(REMOVE_ORGANIZATION, withAlert(removeOrganization));
  yield takeEvery(FETCH_COLLECT_ORGANIZATION, withAlert(fetchCollectOrganization));
  yield takeEvery(MERGE_ORGANIZATIONS, withAlert(mergeOrganizations));
}
