import moment from 'moment';

moment.updateLocale('en', { week: { dow: 1 } });

export const ONE_DAY = 86400000;
export const ONE_WEEK = 604800000;
export const getInitDate = () => {
  const now = moment();
  const week = now.week();

  return moment(`${now.year()}-W${week >= 10 ? '' : '0'}${week}`).toDate();
};
const LOCALE = 'de-CH';
const dayFormat = new Intl.DateTimeFormat(LOCALE, { weekday: 'short' });
const dateFormat = new Intl.DateTimeFormat(LOCALE, { day: '2-digit' });
const monthFormat = new Intl.DateTimeFormat(LOCALE, { month: 'long' });
const yearFormat = new Intl.DateTimeFormat(LOCALE, { year: 'numeric' });

export const getDateKey = (date) => moment(date).format('YYYY-MM-DD');
export const getNames = (date) => ({
  weekday: dayFormat.format(date),
  day: dateFormat.format(date),
  month: monthFormat.format(date),
  year: yearFormat.format(date),
});
