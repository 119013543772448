import { takeLatest, takeEvery, debounce, call, put, select, throttle, takeLeading, all } from 'redux-saga/effects';
import Bugsnag from '@bugsnag/js';
import { prop, path, omit, when, is, pick } from 'ramda';

import api from 'api';
import { withAlert } from 'store/alerts';
import { EXAMINATION_STATUS } from 'store/examinations/consts';
import { getCurrentExaminationId, getCurrentExaminationProp, getCurrentExamination } from 'store/examinations/selectors';
import { updateExamination } from 'store/examinations/actions';
import { APPROVALS, APPROVAL_COMMENT, PROCEDURE, COMMENT } from 'store/diagnoses/consts';
import { getCurrentDiagnosis } from 'store/diagnoses/selectors';
import { updateDiagnosisReports, updateDiagnosis } from 'store/diagnoses/actions';
import { getUserId } from 'store/session/selectors';

import {
  ID,
  INVOICES_FILTERS,
  SUB_PATIENT,
  SUB_EXAMINATION,
  ALL,
  CANTON,
  YEAR,
  LIMIT,
  SORT_BY,
  SORT_DIR,
  CODE,
  ASC,
  SERVICES,
  STATUS,
  INVOICE_STATUS,
  APPROVAL_STATUS,
  SUB_USER,
  CURSOR,
  DESC,
  META,
  CREATED,
} from '.';
import { getCurrentInvoice, getCurrentInvoiceId, getAllTarmeds, getInvoice } from './selectors';
import {
  setCurrentInvoice,
  updateInvoices,
  updateInvoice,
  updateAllTarmeds,
  updatePatientTarmeds,
  updateDashboard,
  updatePagination,
} from './actions';
import {
  FETCH_DASHBOARD,
  FETCH_INVOICES,
  FETCH_INVOICE,
  FETCH_INVOICES_BY_EXAM_ID,
  FETCH_INVOICES_BY_PATIENT,
  CREATE_INVOICE,
  SAVE_INVOICE,
  SAVE_PATIENT_INVOICE,
  SEND_INVOICE_REPORT,
  SEND_PATIENT_INVOICE,
  CANCEL_INVOICE,
  RESEND_INVOICE,
  FETCH_BASE_TARMEDS,
  FETCH_PATIENT_TARMEDS,
  FETCH_KPI_BY_NURSING_HOME_ID,
  DOWNLOAD_PDF,
  FETCH_CANTON_DASHBOARD,
} from './types';

function* fetchDashboard() {
  const data = yield call(api.getDashboard);
  yield put(updateDashboard(data));
}

function* fetchNursingHomeKPIs({ payload }) {
  return { success: yield call(api.getNursingHomeKPIs, payload) };
}

function* fetchInvoices({ payload }) {
  const data = yield call(api.getInvoices, payload);
  yield put(updateInvoices(data.data));
  yield put(updatePagination({ kind: INVOICES_FILTERS, value: omit(['data'], data) }));
}

function* checkTarmeds() {
  const allTarmeds = yield select(getAllTarmeds);

  if (!allTarmeds?.length) {
    const { data } = yield call(api.getTarmedPositions, { [LIMIT]: 50 });

    yield put(updateAllTarmeds(data || []));
  }
}

function* fetchInvoicesByPatient({ payload }) {
  const { data } = yield call(api.getInvoices, {
    [SUB_PATIENT]: payload,
    [LIMIT]: 100,
    [CURSOR]: 0,
    [SORT_BY]: `${META}.${CREATED}`,
    [SORT_DIR]: DESC,
  });
  yield checkTarmeds();

  return { success: data };
}

function* fetchInvoice({ payload }) {
  const existingInvoice = yield select(getInvoice(payload));

  if (!existingInvoice) {
    const data = yield call(api.getInvoice, payload);
    yield put(updateInvoice(data));
  }

  yield checkTarmeds();
}

function* fetchInvoicesByExamId({ payload }) {
  const data = yield call(api.getInvoices, { [SUB_EXAMINATION]: payload });
  yield put(setCurrentInvoice(path(['data', 0, ID], data)));
  yield put(updateInvoices(data.data));
}

function* createInvoice({ payload }) {
  return { success: yield call(api.postInvoice, payload) };
}

function* saveInvoice({ payload }) {
  const invoice = yield select(getCurrentInvoice);

  if (!invoice) {
    const examId = yield select(getCurrentExaminationId);
    const patient = yield select(getCurrentExaminationProp(SUB_PATIENT));
    const data = yield call(api.postInvoice, {
      [SUB_PATIENT]: when(is(Object), prop(ID), patient),
      [SUB_EXAMINATION]: examId,
      [SERVICES]: payload,
    });

    yield put(setCurrentInvoice(prop(ID, data)));
    yield put(updateInvoice(data));
  } else {
    const data = yield call(api.patchInvoice, invoice[ID], { [SERVICES]: payload });

    yield put(updateInvoice(data));
  }
}

function* savePatientInvoice({ payload }) {
  yield call(api.patchInvoice, payload[ID], pick([SERVICES], payload));
}

function* cancelInvoice() {
  const id = yield select(getCurrentInvoiceId);

  if (id) {
    const data = yield call(api.cancelInvoice, id);
    yield put(updateInvoice(data));
  }
}

function* resendInvoice({ payload }) {
  if (payload) {
    const data = yield call(api.resendInvoice, payload);
    yield put(updateInvoice(data));
  }
}

function* fetchBaseTarmeds({ payload }) {
  const { data } = yield call(api.getTarmedPositions, {
    [SUB_PATIENT]: payload,
    [LIMIT]: 50,
    [SORT_BY]: CODE,
    [SORT_DIR]: ASC,
    default: true,
  });

  return { success: data || [] };
}

function* fetchPatientTarmeds({ payload }) {
  const { data } = yield call(api.getTarmedPositions, payload);

  yield checkTarmeds();
  yield put(updatePatientTarmeds(data || []));
}

function* downloadPdf({ payload }) {
  return { success: yield call(api.downloadPdf, payload) };
}

function* fetchCantonDashboard({ payload }) {
  return { success: yield call(api.getCantonDashboard, payload[YEAR], payload[CANTON] === ALL ? '' : payload[CANTON]) };
}

const bugsnagNotify = (error) => {
  if (process.env.NODE_ENV === 'production') Bugsnag.notify(error);
};

function* sendInvoiceReport({ payload }) {
  const { [ID]: invoiceId, [STATUS]: invoiceStatus } = (yield select(getCurrentInvoice)) || {};
  const { [ID]: diagnosisId, [APPROVALS]: approvals, [PROCEDURE]: procedure } = yield select(getCurrentDiagnosis);

  if (!(procedure && procedure[COMMENT])) {
    const error = new Error('Der Befund ist unvollständig. Geben Sie im Reiter Diagnose eine Empfehlung ab.');

    bugsnagNotify(error);

    throw error;
  }

  const examination = yield select(getCurrentExamination);
  const examStatus = examination[STATUS];
  const userId = yield select(getUserId);
  const updatedApprovals = [
    ...approvals.map((approval) => ({
      ...approval,
      [SUB_USER]: when(is(Object), prop(ID))(approval[SUB_USER]),
    })),
    {
      status: APPROVAL_STATUS.SENT,
      message: payload,
      timestamp: new Date().toISOString(),
      [SUB_USER]: userId,
    },
  ];

  yield put(updateExamination({ ...examination, [STATUS]: EXAMINATION_STATUS.RELEASED }));

  try {
    const [updatedInvoice, reportData, updatedExam, updatedDiagnosis] = yield all([
      invoiceId && invoiceStatus === INVOICE_STATUS.IN_PREPARATION && call(api.sendInvoice, invoiceId),
      call(api.postDiagnosisReports, diagnosisId),
      call(api.putExamination, { [ID]: examination[ID], [STATUS]: EXAMINATION_STATUS.RELEASED }),
      call(api.patchDiagnosis, { diagnosisId, [APPROVALS]: updatedApprovals, [APPROVAL_COMMENT]: '' }),
    ]);

    if (updatedInvoice) yield put(updateInvoice(updatedInvoice));
    if (reportData?.data) yield put(updateDiagnosisReports(reportData.data));

    yield put(updateExamination(updatedExam));
    yield put(updateDiagnosis(updatedDiagnosis));
  } catch (e) {
    yield put(updateExamination({ ...examination, [STATUS]: examStatus }));

    throw e;
  }
}

function* sendPatientInvoice({ payload }) {
  return { success: yield call(api.sendInvoice, payload) };
}

export default function* watchInvoices() {
  yield takeLatest(FETCH_KPI_BY_NURSING_HOME_ID, withAlert(fetchNursingHomeKPIs));
  yield takeLatest(FETCH_DASHBOARD, withAlert(fetchDashboard));
  yield throttle(500, FETCH_INVOICES, withAlert(fetchInvoices));
  yield takeLatest(FETCH_INVOICE, withAlert(fetchInvoice));
  yield takeLatest(FETCH_INVOICES_BY_EXAM_ID, withAlert(fetchInvoicesByExamId));
  yield takeLatest(FETCH_INVOICES_BY_PATIENT, withAlert(fetchInvoicesByPatient));
  yield takeEvery(CREATE_INVOICE, withAlert(createInvoice));
  yield debounce(500, SAVE_INVOICE, withAlert(saveInvoice));
  yield debounce(500, SAVE_PATIENT_INVOICE, withAlert(savePatientInvoice));
  yield takeLeading(SEND_INVOICE_REPORT, withAlert(sendInvoiceReport));
  yield takeLeading(SEND_PATIENT_INVOICE, withAlert(sendPatientInvoice));
  yield takeEvery(CANCEL_INVOICE, withAlert(cancelInvoice));
  yield takeEvery(RESEND_INVOICE, withAlert(resendInvoice));
  yield takeLatest(FETCH_BASE_TARMEDS, withAlert(fetchBaseTarmeds));
  yield throttle(500, FETCH_PATIENT_TARMEDS, withAlert(fetchPatientTarmeds));
  yield takeLatest(DOWNLOAD_PDF, withAlert(downloadPdf));
  yield takeLeading(FETCH_CANTON_DASHBOARD, withAlert(fetchCantonDashboard));
}
