import { useMemo } from 'react';
import { any, propEq, propOr, pathOr } from 'ramda';

import { CORRESPONDENCE, OTHER_CONTACTS, PATIENT, NO_REPORT, NURSING_HOME, usePatient, SUB_ORGANIZATION } from 'store/patients';
import { NO_PATIENT_REPORTS } from 'store/organizations';

import { contactNames } from './consts';

const checkContact = propOr(false, CORRESPONDENCE);
const checkContacts = any(propEq(true, CORRESPONDENCE));

export const useContactsForPatient = (patientId) => {
  const patient = usePatient(patientId) || null;
  const noPatientReports = useMemo(() => pathOr(false, [NURSING_HOME, SUB_ORGANIZATION, NO_PATIENT_REPORTS], patient), [patient]);

  return useMemo(
    () =>
      contactNames.filter(
        ({ id }) =>
          (id === PATIENT && !noPatientReports && patient && !patient[NO_REPORT]) ||
          (id === 'contact' ? checkContacts(propOr([], OTHER_CONTACTS, patient)) : checkContact(propOr({}, id, patient)))
      ),
    [patient, noPatientReports]
  );
};
