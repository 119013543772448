import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { path, is } from 'ramda';
import PropTypes from 'prop-types';

import { KIND, useAlerts, useOrganizationActions } from 'store/organizations';
import { usePermissions, ORGANIZATION } from 'permissions';
import AddItemBar from 'components/AddItemBar';

import { items, LABELS } from './utils';
import List from './List';
import Duplicates from './Duplicates';
import { Container, Wrapper, Item } from './styles';

const Organizations = ({ history, location }) => {
  const { createOrganization } = useOrganizationActions();
  const { extractId, resetAlerts, ...alerts } = useAlerts();
  const can = usePermissions();

  const [kind, setKind] = useState(path(['state', KIND], location) || path([0, 'key'], items));

  const createNursingHome = useCallback(() => {
    if (alerts.loading) return;

    extractId(createOrganization({ [KIND]: kind }));
  }, [createOrganization, extractId, kind, alerts.loading]);

  useEffect(() => {
    if (is(String, alerts.success)) {
      history.push(`/organizations/${alerts.success}`, { [KIND]: kind });
      resetAlerts();
    }
  }, [history, kind, resetAlerts, alerts.success]);

  const labels = useMemo(() => LABELS[kind], [kind]);

  return (
    <Container>
      <Wrapper>
        {items.map(
          ({ label, key, isNurseOnly, alignRight }) =>
            (isNurseOnly && !can.delete(ORGANIZATION)) || (
              <Item key={key} isActive={key === kind} onClick={() => setKind(key)} $alignRight={alignRight}>
                {label}
              </Item>
            )
        )}
      </Wrapper>
      {kind === 'duplicates' ? (
        <Duplicates />
      ) : (
        <>
          <AddItemBar label={labels.NEW} onClick={createNursingHome} disabled={alerts.loading} />
          <List key={kind} kind={kind} />
        </>
      )}
    </Container>
  );
};

Organizations.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      kind: PropTypes.number,
    }),
  }).isRequired,
};

export default Organizations;
