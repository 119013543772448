import { useMemo } from 'react';
import { paths } from 'ramda';

import { useInitInvoice, useInvoice, SUB_PATIENT, SERVICES, STATUS, INVOICE_STATUS, SUB_COPY } from 'store/invoices';
import { NURSING_HOME, SUB_ORGANIZATION } from 'store/patients';
import { useOrganization } from 'store/organizations';
import { INVOICE, usePermissions } from 'permissions';

export const useInitData = (invoiceId) => {
  const can = usePermissions();
  const invoice = useInvoice(invoiceId);
  const [patient, nursingHomeId, services, status, copy] = useMemo(
    () => paths([[SUB_PATIENT], [SUB_PATIENT, NURSING_HOME, SUB_ORGANIZATION], [SERVICES], [STATUS], [SUB_COPY]], invoice || {}),
    [invoice]
  );
  const canResend = useMemo(
    () => can.update(INVOICE) && ((status === INVOICE_STATUS.REJECTED && !copy) || status === INVOICE_STATUS.ERROR),
    [can, copy, status]
  );

  const { loading, organization } = useOrganization(nursingHomeId);
  const { loading: invoiceLoading } = useInitInvoice(can.read(INVOICE), invoiceId);

  return {
    isLoading: !invoice || invoiceLoading || loading,
    invoice,
    patient: patient || {},
    services,
    canResend,
    nursingHome: organization || {},
  };
};
