import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from 'assets/svg-icons/arrowRight.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

export const Panel = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40px;
  padding: 0 10px;
  background-color: var(--color-greyF8);
  border: 1px solid;
  border-color: var(--color-greyF8);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 38px;
  letter-spacing: 0.92px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  &:disabled {
    background-color: var(--color-white);
    border-color: var(--color-grey6A);
    cursor: not-allowed;
  }
  @media (hover) {
    &:hover:enabled {
      background-color: var(--color-greyEC);
    }
  }
`;

export const Icon = styled(ArrowIcon)`
  transform: rotate(${({ $rotate }) => ($rotate ? 180 : 0)}deg);
`;

export const Title = styled.p`
  font-size: var(--font-size18);
  font-weight: 400;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
`;

export const Head = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
`;

export const HeadLabel = styled.p`
  justify-self: center;
  font-size: var(--font-size14);
  font-weight: var(--font-weight400);
  color: ${({ $isGray }) => ($isGray ? '#bdbcbc' : 'var(--color-grey6A)')};
`;

export const HeadText = styled.p`
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  aspect-ratio: 1/1;
  background-color: ${({ $isActive }) => ($isActive ? 'var(--color-blue4A)' : 'none')};
  font-size: var(--font-size18);
  font-weight: var(--font-weight500);
  color: ${({ $isActive, $isGray }) => ($isActive && 'var(--color-white)') || ($isGray && '#bdbcbc') || 'var(--color-grey6A)'};
  border-radius: 50%;
  margin: 3px 0;
`;

export const ContentWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  min-height: 80px;
  width: 100%;
  position: relative;
  border: solid var(--color-grey97);
  border-width: 0 1px 1px;
  background: repeating-linear-gradient(
    90deg,
    var(--color-white),
    var(--color-white) calc((100% + 1px) / 7 - 1px),
    var(--color-grey97) calc((100% + 1px) / 7 - 1px),
    var(--color-grey97) calc((100% + 1px) / 7)
  );
  padding: 2px 0;
`;

export const Item = styled(Link)`
  grid-column: ${({ $column, $days }) => `${$column + 1}/${Math.min(8, $column + $days + 1)}`};
  grid-row: ${({ $row }) => `${$row + 1}/${$row + 2}`};
  display: flex;
  height: fit-content;
  width: calc(100% - 5px);
  background-color: ${({ $color }) => $color || 'var(--color-grey6A)'};
  border-radius: 4px;
  text-decoration: none;
  padding: 3px 5px;
  margin-left: 1px;
  & > span {
    font-size: var(--font-size13);
    font-weight: var(--font-weight400);
    color: var(--color-white);
    hyphens: auto;
  }
`;

export const SpinnerWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  inset: -1px;
  background-color: rgba(255, 255, 255, 0.75);
`;
