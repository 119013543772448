import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 40px;
  margin: 15px 0;
  & > div {
    flex: 1 1 auto;
    margin: 0;
  }
`;

export const Label = styled.p`
  line-height: 40px;
  color: var(--color-grey6A);
`;
