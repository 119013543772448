import { pipe, props, filter, join } from 'ramda';

import { ORGANIZATION_KINDS, OPHTHALMOLOGY, DOCTOR, FIELDS, NAME, ZIP_CODE, CITY, CANTON } from 'store/organizations';

export const RADIOS = [
  { label: 'Pflegezentrum', value: String(ORGANIZATION_KINDS.NURSING_HOME) },
  { label: 'Optiker', value: String(ORGANIZATION_KINDS.OPTICIAN) },
  { label: 'Augenarzt', value: `${ORGANIZATION_KINDS.CLINIC}&${OPHTHALMOLOGY}` },
  { label: 'Hausarzt', value: `${ORGANIZATION_KINDS.CLINIC}&${DOCTOR}` },
];

export const PARAMS = {
  [FIELDS]: [NAME, ZIP_CODE, CITY, CANTON],
};

export const getName = (item = {}) =>
  pipe(($) => [$[NAME], pipe(props([ZIP_CODE, CITY, CANTON]), filter(Boolean), join(' '))($)], filter(Boolean), join(', '))(item);
