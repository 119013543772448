import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { props, values } from 'ramda';
import PropTypes from 'prop-types';

import { useAlerts } from 'store/alerts/hooks';
import { useAsyncState } from 'utils/useAsyncState';
import { CURSOR, NEXT, SEARCH, HAS_MORE } from 'api/consts';
import Spinner from 'components/FullScreenSpinner';

import { ADD, REMOVE } from './consts';
import { getSelected } from './utils';
import Item from './Item';
import SelectedItem from './SelectedItem';
import { Container, Input, Wrapper, CollapseBox, Content, SpinnerWrap, Message, SelectedWrap } from './styles';

const PaginationDropDown = ({
  idKey,
  nameKey,
  action,
  value,
  onChange,
  onSelect,
  placeholder,
  onlyOne,
  disabled,
  applyFilter,
  latestItem,
  selectedComponent,
  withoutRemoving,
  className,
  disabledId,
}) => {
  const inputRef = useRef();
  const contentRef = useRef();
  const randomId = useMemo(() => `preventBubbling${String(Math.random()).slice(2)}`, []);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useAsyncState({ [SEARCH]: '', [CURSOR]: 0 });
  const [list, setList] = useAsyncState();
  const filteredList = useMemo(
    () => (list && typeof applyFilter === 'function' && applyFilter(list)) || list,
    [applyFilter, list]
  );

  const { id, extractId, loading, success, error } = useAlerts();
  const isLoading = !id || loading || !list;
  const [nextCursor, hasMore] = useMemo(() => props([NEXT, HAS_MORE], success || {}), [success]);
  const handleSearch = useCallback(
    (val) => {
      setQuery({ [SEARCH]: val, [CURSOR]: 0 });
      setList();
      if (contentRef.current) contentRef.current.scrollTop = 0;
    },
    [setQuery, setList]
  );

  const handleFocus = useCallback(() => setIsOpen(true), []);
  const handleClick = useCallback(
    (e) => {
      if (!e.target.closest(`#${randomId}`) && isOpen) setIsOpen(false);
    },
    [randomId, isOpen]
  );
  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Escape') setIsOpen(false);
    if (e.key === 'Escape' && inputRef.current) inputRef.current.blur();
  }, []);
  const handleScroll = useCallback(
    (e) => {
      const { scrollHeight, scrollTop } = e.target;
      if (isLoading) {
        e.target.scrollTop = scrollTop - 2;
        return;
      }
      if (!(hasMore && nextCursor)) return;

      const contentHeight = e.target?.getBoundingClientRect()?.height;

      if (scrollTop + contentHeight === scrollHeight) {
        setQuery(($) => ({ ...$, [CURSOR]: nextCursor }));
      }
    },
    [hasMore, isLoading, nextCursor, setQuery]
  );

  const handleSelect = useCallback(
    (type, item) => {
      if (type === ADD && onlyOne) {
        onChange(item);
        setIsOpen(false);
      }
      if (type === ADD && !onlyOne) onChange([item, ...value]);

      if (type === REMOVE && onlyOne) onChange(null);
      if (type === REMOVE && !onlyOne) {
        const val = value.filter(($) => $[idKey] !== item[idKey]);
        onChange(val);
      }
    },
    [idKey, onChange, onlyOne, value]
  );
  const onRemove = useCallback((item) => handleSelect(REMOVE, item), [handleSelect]);

  const selected = useMemo(() => getSelected(value, idKey), [value, idKey]);
  const selectedList = useMemo(() => values(selected), [selected]);

  useEffect(() => {
    document.body.addEventListener('click', handleClick);
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      document.body.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleClick, handleKeyPress]);
  useEffect(() => {
    extractId(action(query));
  }, [action, extractId, query]);
  useEffect(() => {
    if (success?.data) setList(($) => ($ ? [...$, ...success.data] : success.data));
  }, [success, setList]);
  useEffect(() => {
    if (typeof onSelect === 'function') {
      onSelect(selectedList);
    }
  }, [onSelect, selectedList]);

  const SelectedComponent = selectedComponent || SelectedItem;

  return (
    <Container id={randomId} className={className}>
      {!disabled && (
        <>
          <Input
            ref={inputRef}
            type="search"
            value={query[SEARCH]}
            handleChangeText={handleSearch}
            onFocus={handleFocus}
            placeholder={placeholder}
            isOpen={isOpen}
            autoComplete="off"
            mb={0}
          />
          <Wrapper>
            <CollapseBox isOpen={isOpen}>
              <Content ref={contentRef} isLoading={isLoading} onScroll={handleScroll}>
                <div>
                  {Boolean(filteredList?.length) &&
                    filteredList.map((item) => (
                      <Item
                        key={item[idKey]}
                        item={item}
                        nameKey={nameKey}
                        type={selected[item[idKey]] && !withoutRemoving ? REMOVE : ADD}
                        onSelect={handleSelect}
                        isSelected={Boolean(selected[item[idKey]])}
                      />
                    ))}
                  {!hasMore && latestItem}
                </div>
                <div>
                  {filteredList?.length === 0 && !isLoading && <Message>Kein Eintrag gefunden</Message>}
                  {error && !isLoading && <Message isError>{error}</Message>}
                </div>
              </Content>
              {isLoading && (
                <SpinnerWrap>
                  <Spinner size={30} />
                </SpinnerWrap>
              )}
            </CollapseBox>
          </Wrapper>
        </>
      )}
      {selectedList.length > 0 && (
        <SelectedWrap disabled={disabled}>
          {selectedList.map((item) => (
            <SelectedComponent
              key={item[idKey]}
              item={item}
              nameKey={nameKey}
              onDelete={onRemove}
              disabled={disabled || item[idKey] === disabledId}
            />
          ))}
        </SelectedWrap>
      )}
    </Container>
  );
};

PaginationDropDown.defaultProps = {
  value: null,
  onChange: () => null,
  onSelect: null,
  placeholder: '',
  onlyOne: false,
  disabled: false,
  applyFilter: null,
  selectedComponent: null,
  className: '',
  disabledId: null,
  latestItem: null,
  withoutRemoving: false,
};

PaginationDropDown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))]),
  idKey: PropTypes.string.isRequired,
  nameKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  action: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  onlyOne: PropTypes.bool,
  disabled: PropTypes.bool,
  applyFilter: PropTypes.func,
  selectedComponent: PropTypes.elementType,
  withoutRemoving: PropTypes.bool,
  className: PropTypes.string,
  disabledId: PropTypes.string,
  latestItem: PropTypes.element,
};

export default PaginationDropDown;
