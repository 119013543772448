import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { usePlanningActions, useAlerts } from 'store/planning';
import Confirm from 'components/Confirm';

import { Title } from './styles';

const Removing = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const { removeSchedule, resetCurrent } = usePlanningActions();
  const { action, loading, success } = useAlerts(removeSchedule);
  const onClick = useCallback(() => action(id), [action, id]);

  useEffect(() => {
    if (success) {
      push('/planning');

      return resetCurrent;
    }

    return () => {};
  }, [push, resetCurrent, success]);

  return (
    <>
      <Title title="Terminplan löschen" />
      <Confirm title="Löschen" onClick={onClick} disabled={loading} />
    </>
  );
};

export default Removing;
