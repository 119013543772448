import React, { useState, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { head, pipe, props, filter, join } from 'ramda';
import PropTypes from 'prop-types';

import {
  ID,
  NAME,
  SUB_COMPANY,
  NUMBER,
  // VEKA,
  MODEL,
  SUPPLEMENT,
  SSN,
  REMUNERATION,
  LAW_TYPE,
  LAW_TYPES,
  LAW_TYPE_LABELS,
  ZIP_CODE,
  TOWN,
  COUNTRY,
  usePatientsActions,
} from 'store/patients';
import TextInput from 'components/TextInputNew';
import PaginationDropDown from 'components/PaginationDropDown';
import Radios from 'components/RadiosNew';
import { SectionWrap, Label } from '../styles';

import { getPath, modelItems, yesNoItems, remunerationItems, lawTypes } from './utils';
import Item from './Item';
import { Warning } from './styles';

const Insurance = ({ readMode, error }) => {
  const [{ value: insuranceCompany }, , { setValue: setInsuranceCompany }] = useField(getPath(SUB_COMPANY));
  const [numberField] = useField(getPath(NUMBER));
  // const [vekaField] = useField(getPath(VEKA));
  const [{ value: model }, , { setValue: setModel }] = useField(getPath(MODEL));
  const [{ value: supplement }, , { setValue: setSupplement }] = useField(getPath(SUPPLEMENT));
  const [ssnField] = useField(getPath(SSN));
  const [{ value: remuneration }, , { setValue: setRemuneration }] = useField(getPath(REMUNERATION));
  const [{ value: lawType }, , { setValue: setLawType }] = useField(getPath(LAW_TYPE));
  const [selectedInsurance, setSelectedInsurance] = useState();
  const { fetchInsurances } = usePatientsActions();
  const getInsurances = useCallback(
    (query) => fetchInsurances({ ...query, ...(typeof lawType === 'number' && { [LAW_TYPE]: lawType }) }),
    [fetchInsurances, lawType]
  );
  const onSelect = useCallback((insurances) => setSelectedInsurance(head(insurances || [])), []);
  const warnMessage = useMemo(() => {
    if (selectedInsurance && typeof lawType === 'number' && !(selectedInsurance[LAW_TYPES] || []).includes(lawType)) {
      return `Die Krankenkasse unterstützt das Gesetz ${LAW_TYPE_LABELS[lawType]} nicht`;
    }

    return null;
  }, [lawType, selectedInsurance]);
  const getItemName = useCallback(
    (item) => [item[NAME], pipe(props([ZIP_CODE, TOWN, COUNTRY]), filter(Boolean), join(' '))(item)].filter(Boolean).join(', '),
    []
  );

  return (
    <>
      <SectionWrap>
        <Label>Krankenkasse</Label>
        <PaginationDropDown
          key={lawType}
          idKey={ID}
          nameKey={getItemName}
          action={getInsurances}
          placeholder="Suchen"
          value={insuranceCompany}
          onChange={setInsuranceCompany}
          onSelect={onSelect}
          disabled={readMode}
          selectedComponent={Item}
          withoutRemoving
          onlyOne
        />
      </SectionWrap>
      {warnMessage && <Warning>{warnMessage}</Warning>}
      {/* <TextInput label="VEKA-Nr." {...vekaField} readMode={readMode} /> */}
      <Radios
        label="Gesetz"
        items={lawTypes}
        value={lawType}
        setValue={setLawType}
        readMode={readMode}
        error={typeof lawType !== 'number' && 'Kein Gesetz ausgewählt'}
      />
      <Radios label="Versicherungsmodell" items={modelItems} value={model} setValue={setModel} readMode={readMode} />
      <Radios label="Zusatzversicherung" items={yesNoItems} value={supplement} setValue={setSupplement} readMode={readMode} />
      <TextInput label="AHV-Nr." {...ssnField} error={!readMode && error} readMode={readMode} />
      <TextInput label="Versichertennummer" {...numberField} readMode={readMode} />
      <Radios label="Vergütung" items={remunerationItems} value={remuneration} setValue={setRemuneration} readMode={readMode} />
    </>
  );
};

Insurance.defaultProps = { error: null };
Insurance.propTypes = {
  readMode: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default Insurance;
