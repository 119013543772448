import React, { useState, useMemo, useCallback } from 'react';
import { times } from 'ramda';

import Message from 'components/Message';
import Spinner from 'components/FullScreenSpinner';

import { ONE_DAY, ONE_WEEK, getInitDate, getDateKey, getNames } from './utils';
import { useScheduleLines } from './hooks';
import { Container, Panel, Button, Icon, Title, Head, HeadLabel, HeadText, ContentWrap, Item, SpinnerWrap } from './styles';

const Calendar = () => {
  const initDate = useMemo(() => getInitDate(), []);
  const [startDate, setStartDate] = useState(initDate);
  const today = useMemo(() => getDateKey(new Date()), []);
  const [weekDays, title] = useMemo(() => {
    const days = times((index) => {
      const date = new Date(startDate.valueOf() + index * ONE_DAY);
      const key = getDateKey(date);
      const day = date.getDay();

      return {
        key,
        index,
        date,
        ...getNames(date),
        isActive: key === today,
        isGray: day === 6 || day === 0,
      };
    }, 7);

    return [
      days,
      (
        (days[0].year !== days[6].year && [days[0].month, days[0].year, '–', days[6].month, days[6].year]) ||
        (days[0].month !== days[6].month && [days[0].month, '–', days[6].month, days[6].year]) || [days[0].month, days[0].year]
      ).join(' '),
    ];
  }, [startDate, today]);
  const { list, loading, error } = useScheduleLines(weekDays);

  const onReset = useCallback(() => setStartDate(initDate), [initDate]);
  const onPrev = useCallback(() => setStartDate(($) => new Date($.valueOf() - ONE_WEEK)), []);
  const onNext = useCallback(() => setStartDate(($) => new Date($.valueOf() + ONE_WEEK)), []);

  return (
    <Container>
      <Panel>
        <Button type="button" onClick={onReset} disabled={startDate.valueOf() === initDate.valueOf()}>
          Heute
        </Button>
        <Button type="button" onClick={onPrev}>
          <Icon $rotate />
        </Button>
        <Button type="button" onClick={onNext}>
          <Icon />
        </Button>
        <Title>{title}</Title>
      </Panel>
      {error && (
        <Message type="error" form={1}>
          {error}
        </Message>
      )}
      <Head>
        {weekDays.map(({ key, weekday, day, isActive, isGray }) => (
          <div key={key}>
            <HeadLabel $isActive={isActive} $isGray={isGray}>
              {weekday}
            </HeadLabel>
            <HeadText $isActive={isActive} $isGray={isGray}>
              {day}
            </HeadText>
          </div>
        ))}
      </Head>
      <ContentWrap>
        {list.map((items) =>
          items.map(({ id, column, row, name, days, color }) => (
            <Item key={id} to={`/planning/${id}`} $column={column} $row={row} $days={days} $color={color}>
              <span>{name}</span>
            </Item>
          ))
        )}
        {loading && (
          <SpinnerWrap>
            <Spinner size={30} />
          </SpinnerWrap>
        )}
      </ContentWrap>
    </Container>
  );
};

export default Calendar;
